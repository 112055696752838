<template>
  <div id="notifelement" class="notifcontact">
    
      <div class="block-text mb-small">
          <div class="section-title mb-20">
              <h2>Your registration is successful.</h2>
              <h5>Congratulations you are now a member of Dijiwa Club</h5>
          </div>
          <a :href="getHomeUrl()" class="main-btn btn-filled mt-40">Back Home</a>
      </div>

  </div>
</template>

<script>
import AxiosMixin from "./mixins/axiosMixins.js";

export default {
  props: ["dataprop"],
  mixins: [AxiosMixin],
  data() {
    return {
      url: "",
      eventname:process.env.MIX_APP_NAME,
    
    };
  },
  created() {
  },
  methods: {
    initial() {

    },
    getHomeUrl(){
      return `/`
    }
  },
  mounted: function(){
    var target = document.querySelector("#notifelement");
            // animate
    $('html, body').animate({
        scrollTop: $(target).offset().top
    }, 500);
  }
};
</script>
<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
<style>
  .multiselect__input{
    width:0px!important; 
  }


</style>
