
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../../bootstrap';
// import '../../plugins';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMoment from 'vue-moment';



// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue.use(BootstrapVue)



import birthDatepicker from 'vue-birth-datepicker/src/birth-datepicker';

const VueUploadComponent = require('vue-upload-component')

window.Vue = Vue;

const phoneOrEmailRule = {
  getMessage(field, args) {
    return `The ${field} must be either a valid phone number`;
  },
  validate(value, args) {

    // Custom regex for a phone number 
    const MOBILEREG = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    return MOBILEREG.test(value);
  }
};


Vue.use(VueMoment);
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: "veeFields",
    errorBagName: "veeErrors"
});

VeeValidate.Validator.extend('phone', phoneOrEmailRule);

Vue.component('birth-datepicker', birthDatepicker)

Vue.component('show-form', require('./components/Form.vue').default);
Vue.component('show-notif', require('./components/Notif.vue').default);


const app = new Vue({
    el: '#app',
    data: {
        choosedData:{},
        isShowForm:true,
        isShowNotif:false,
    },
    methods: {
                
        getData(value){
            if(value.action=='form'){
                this.isShowForm=true
                this.isShowNotif=false
            }else if(value.action=='notif'){
                this.isShowForm=false
                this.isShowNotif=true
            }
            this.choosedData = value.data
        }

    }
});