<template>
  <div>

    <form>

      <div class="row">
        <div class="col-md-6">
          <div class="input-group mt-30">
            <span class="icon"><i class="far fa-user"></i></span>
            <input type="text" name="name" 
            v-model="data.name" 
            v-validate="'required|alpha_spaces'"
            placeholder="Your full name">
          </div>
          <div class="text-danger mb-10">{{ veeErrors.first('name') }}</div>
        </div>
        <div class="col-md-6">

          <div class="input-group birthday mt-30">
            <span class="icon"><i class="far fa-calendar"></i></span>
            <birth-datepicker 
            name="birthday"
            :value-is-string="true"
            :hide-header="true"
            :high="false"
            delimiter="-"
            v-model="birthday"
            placeholder="Birthday"
            v-validate="'required'"
            />
          </div>
          <div class="text-danger mb-10">{{ veeErrors.first('birthday') }}</div>
        </div>
        <div class="col-md-6">
          <div class="input-group mt-30">
            <span class="icon"><i class="far fa-envelope"></i></span>
            <input type="email" name="email" 
            v-model="data.email"
            v-validate="'required|email|email_exists'"
            placeholder="Enter email address">
          </div>
          <div class="text-danger mb-10">{{ veeErrors.first('email') }}</div>
        </div>
        <div class="col-md-6">
          <div class="input-group mt-30">
            <phone-number 
                :translations="trans" 
                :class="'whatsapp-input'" 
                name="whatsapp" 
                v-validate="'required'" 
                size="lg" 
                v-model="whatsapp"
                :no-flags="true" 
                @update="showPayload" 
                :default-country-code="'ID'">
            </phone-number>
          </div>
          <div class="text-danger mb-10">{{ veeErrors.first('whatsapp') }}</div>
        </div>
        <div class="col-md-12">
          <div class="input-group mt-30">
            <span class="icon"><i class="far fa-map"></i></span>
            <input type="text" name="address"
            v-model="data.address" 
            v-validate="'required'"
            placeholder="Address">
          </div>
          <div class="text-danger mb-10">{{ veeErrors.first('address') }}</div>
        </div>
        <div class="col-md-12">
          <div class="input-group mt-10 mb-30">
            <p> <i>
                By filling out this form, I consent to the use of my personal information to keep in touch with exclusive offers and the latest info from Dijiwa Sanctuaries. You can find out about your rights and choices, and how we use your information in our <a href="https://dijiwasanctuaries.com/privacy-policy">Privacy Policy</a>.</i>
            </p>
          </div>
        </div>
        <div class="col-12 text-center mb-30">
          <button class="main-btn btn-filled" @click.prevent="submitRegistrasi">Register Now</button>
        </div>
      </div>
    </form>


  </div>
</template>

<script>
import AxiosMixin from "./mixins/axiosMixins.js";
import PhoneNumber from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
            PhoneNumber,
  },
  props: ["dataprop"],
  mixins: [AxiosMixin],
  data() {
    return {
      url: '/member/register',
      type: '',
      whatsapp:'',
      data: {},
      selectedProperty:null,
      propertyList:[],
      birthday:null,
      accept:false,
      source:dataEnquiry.source,
      trans:{
        countrySelectorLabel: 'Country Code',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Enter Whatsapp',
        example: 'Ex. :'
      },
      selected: new Date(),
            showWeekNumber: false,
            locale: undefined // Browser locale
    };
  },
  created() {
    this.initial();
  },
  methods: {
    initial() {
      this.findFirstProperty()
      this.checkEmail()
    },
     clearDate () {
            this.selected = null
        },
    checkEmail(){
      this.$validator.extend('email_exists', {
            getMessage: field => `This email has been registered`,
            validate: value => {
                return axios.get('/checkemail/'+value).then(response => {
                    return {
                        valid: response.data.valid
                    }
                }).catch(error=> { return { error: response.error.val } });
            }
        });
    },
    async findFirstProperty(){
      let url = `/property/findfirst`
      await axios.post(url, {uuid: dataEnquiry.property}).then((res) => {
        this.data.property_id = res.data.id
      })
    },
    async getProperty(){
      let url = `/property/list`
      let getData = [{value: null, text: 'Pilih Property', disabled: true}]
      await axios.post(url, {}).then((res) => {
        $.each(res.data, function(key, val){
          getData.push( {
            value: val.id,
            text: val.name
          })
        })
        this.property = getData
      })
    },
    showPayload(payload){
      this.data.whatsapp = payload.countryCallingCode+payload.nationalNumber
      this.data.country_code = payload.countryCode
    },
    getPropertyList() {
      let self = this
      let ulrlist = `/property/list`
      axios.post(ulrlist, {}).then((response)=> {
        this.propertyList = response.data
        this.selectedProperty = this.propertyList.find(option => option.uuid === dataEnquiry.property)

        // if(this.selectedProperty ){
        //   this.roomList = this.selectedProperty.room
        //   this.selectedRoom = this.roomList.find(option => option.slug === dataEnquiry.room);
        // }
      })
    },
    lableProperty({ name }) {
      return `${name}`;
    },
    submitRegistrasi() {
     this.$validator.validate().then(valid => {
      if (valid) {
        let birthday = this.birthday.split('-')
        birthday = `${birthday[2]}-${birthday[1]}-${birthday[0]}`
        this.data.birthday = birthday
        this.data.source = this.source
        this.submit(this.data)
      }
    });
   },
    submit(value){
      let formData = this.formData(value)
      axios.post(this.url,formData).then((response)=>{
        if(response.data.message === 'success'){
          let data = this.data,
          dataEmit = {
            action:'notif',
            data
          }
          this.$emit("datacomponent",dataEmit);
        }else{
          this.$swal('Error!', 'Something went wrong! Please try again later.', 'error')

        }
      }).catch((err)=>{
        this.errors = err.response.data.errors
        this.$swal('Error!', 'Something went wrong! Please try again later.', 'error')
      })
    },
    formData(obj){
      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
   labelProfesi({ profesi, biaya }) {
    return `${profesi} — Biaya ${biaya}`;
  },
  onChangeProperty(){
    this.data.property_id = this.selectProperty
  },
  alertError(msg) {
    this.$swal({
      icon: "error",
      title: "Maaf terdapat gangguan, silahkan coba kembali",
      text: msg,
      type: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Mengerti",
    }).then((result) => {
      if (result.value) {
      }
    });
  },
},
};
</script>
<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
<style>
    .input-tel__input[data-v-e59be3b4]:hover{
      border-color:unset!important;
    }
    .birthday-picker_dropdown.attach-left {
      left: -80px;
    }
    .whatsapp-input{
        border: none;
        width: 100%;
        height: 70px;
        background-color: #f8f8f8;
        padding: 0 40px;
    }
     .input-tel.lg .input-tel__input[data-v-e59be3b4] {
        font-size: 14px;
        height: 71px;
        min-height: 48px;
    }
    .country-selector.lg .country-selector__input[data-v-46e105de] {
      height: 48px;
      min-height: 71px;
      font-size: 14px;
      border-radius: 0!important;
  }

  .country-selector__input[data-v-46e105de] {
    background-color: unset!important;
  }

  .country-selector__label[data-v-46e105de] {

    left: 10px!important;
  }

  .vue-phone-number-input{
    padding: 0px!important;
  }

  .country-selector__toggle[data-v-46e105de] {

    top: calc(50% - 0px);
  }

  .country-selector.lg .country-selector__country-flag[data-v-46e105de] {
      top: 33px;
  }

  .country-selector.lg.has-value .country-selector__input[data-v-46e105de] {
      padding-top: 8px;
  }
  .input-group .icon.property {
    z-index: 703;
  }

  .input-tel__input[data-v-e59be3b4] {
    /*cursor: pointer;*/
     background-color: unset;
     width: 100%;
       }
  .input-tel__input[data-v-e59be3b4] {
    cursor: text!important;
  }
  .multiselect{
    background-color: #f8f8f8 !important;
  }

  .birthday{
    padding-left: 80px;
    height: 71px!important;
    background-color: #f8f8f8 !important;
  }

  .birthday-picker{
    width: 100%;
    border: unset;
  }

  .birthday .btn.h-auto{
    padding-left: 40px !important;
    font-size: 12px;
    color: #bead8e;
    background-color: #f8f8f8 !important;
  }

  .birthday label.form-control{
    padding: 10px 5px !important;
    background-color: #f8f8f8 !important;
  }

  .birthday .label{
    color: #777777 !important;
  }

  .date .form-control{
    color: #495057;
  }

  .multiselect__tags{
    height: 71px!important;
    /*background-color: #f8f8f8 !important;*/
  }

  .multiselect__select {
    height: 71px!important;
  }

  .multiselect__single {
    padding-left: 64px!important;
    margin-bottom: 8px!important;
    height: 100%;
  }
  .multiselect__input, .multiselect__single {

    line-height: 50px!important;
    font-family: 'Arial'!important;
    font-size: 14px!important;
    font-weight: 400!important;
  }

  .multiselect__placeholder {

    padding-left: 64px!important;
    line-height: 50px!important;
  }

  .multiselect__single {
    display: block!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
  }

  .multiselect__current, .multiselect__select {
    line-height: 6px!important;
  }

  .b-form-btn-label-control.form-control > .form-control {
    font-size: 17px;
    font-weight: 500;
  }

  .multiselect__content-wrapper{
    z-index: 5000!important;
  }

  .multiselect--active {
    z-index: 5000!important;
  }


  .b-form-btn-label-control.form-control > .form-control {
    font-size: 14px!important;
    font-weight: 500;
    font-family: "Arial"!important;
  }

  .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    font-size: 12px;
    font-family: "arial";
  }

  /*//Vue Date range*/

  .vue-daterange-picker[data-v-2359713c] {
   width: 100%;
   height: 70px;
 }

 .reportrange-text[data-v-2359713c] {

  height: 69px;
  width: 100%;
}

.reportrange-text[data-v-2359713c] {
  background: #fff;
  cursor: pointer;
  padding: 23px 70px;
  border: 1px solid #ccc;
  width: 100%;
  font-family: "Roboto", sans-serif;
  color: #777777;

}

.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}

.privacy-checkbox label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
}

.privacy-checkbox input{
    border: none;
    width: 20px; 
    height: 20px;
    background-color: #f8f8f8;
    padding: 0 40px;
    margin-right: 10px
}

@media screen and (max-width: 767px) {
  .birthday-picker .birthday-picker_dropdown-body {
      flex-direction: column;
  }
  .reportrange-text[data-v-2359713c] {
    padding: 12px 70px;
  }
}
</style>
