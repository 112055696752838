export default {
    data() {
        return {
            id: '',
            rencanastudilist: []

        }

    },
    methods: {
        getDayIndex() {
            return [
                { name: "Senin", id: 1 },
                { name: "Selasa", id: 2 },
                { name: "Rabu", id: 3 },
                { name: "Kamis", id: 4 },
                { name: "Jumat", id: 5 },
                { name: "Sabtu", id: 6 },
                { name: "Minggu", id: 7 },
            ]

        },
        dayOfWeekAsString(dayIndex) {
            return ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"][dayIndex - 1] || '';
        },
        timeFormat(time) {
            return time.slice(0, -3);
        },

    },
}
